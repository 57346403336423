.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

/* Card container to handle perspective */
.flip-card-inner {
	width: 100%;
	height: 100%;
	transition: transform 0.5s ease-in-out;
	transform-style: preserve-3d;
	position: relative;
}

/* Apply flip effect */
.flipped {
	transform: rotateY(180deg);
}

/* Front and back faces of the card */
.card-front,
.card-back {
	width: 100%;
	height: 100%;
	backface-visibility: hidden;
	transition: visibility 0s 0.5s; /* Delay visibility change until after the flip animation */
}

/* The back of the card (flipped 180deg) */
.card-back {
	transform: rotateY(180deg); /* Keep the back facing forward when flipped */
	visibility: hidden; /* Hide the back when the card is not flipped */
}

/* Make the back visible only when flipped */
.flipped .card-back {
	visibility: visible;
}
